import { Modal, Button } from 'react-bootstrap';

//================================================================

export default function ModalShell({title, show, onHide, children, hideFooter}){
  return(
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          {title ? title : "Modal.Title"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
      <Modal.Footer hidden={hideFooter}>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
