import { Form, Row, Col, Button, InputGroup, ToggleButton } from 'react-bootstrap';
import { useState, useCallback, useEffect } from 'react';
import { PlasticCodes } from '../../enums';

export default function PlasticCodeChecks({onChange, selected, disabled}) {

  return(
    <Row className="mb-4 g-1">
      {Object.keys(PlasticCodes).map(key =>
        <Col 
          key={`plasticCode-col-${key}`}
          className="col-4 p-1 d-flex justify-content-center align-items-center"
        >
          <ToggleButton
            className="w-100"
            key={`plasticCode-check-${key}`}
            value={PlasticCodes[key]['value']}
            type="checkbox"
            variant="outline-secondary"
            checked={selected.includes(PlasticCodes[key]['value'])}
            disabled={disabled}
            onClick={() => {onChange(PlasticCodes[key]['value'])}}
          >
            {PlasticCodes[key]['name']}
          </ToggleButton>
        </Col>
      )}
    </Row>
  );
}
