import { useParams, Link } from "react-router-dom";

import { Col, Row, Button } from 'react-bootstrap';
import { useState, useEffect, useCallback } from 'react';

import ModalShell from '../common/modal-shell';
import { Toaster, createToast } from '../common/toasts';
import PreviousNext from '../common/previous-next';
import BasicSpinner from '../common/basic-spinner';

import ImageDetails from './image-details';
import ImageUpdateForm from './image-update-form';

//================================================================

export default function ImagePage(){
  const params = useParams();

  const [item, setItem] = useState();
  const [images, setImages] = useState([]);
  const [image, setImage] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [toasts, setToasts] = useState([]);

  //----------------------------------------------------------------

  const fetchItem = useCallback(async () => {
    setItem(await fetch(`/api/item/${params.itemId}`)
    .then((response) => {
      if (response.ok) return response.json();
        return null;
      }
    ));
  });

  useEffect(() => {
    fetchItem();
  }, []);

  //----------------------------------------------------------------

  const fetchImages = useCallback(async (item) => {
    if (!item) return;

    const fetchResult = await fetch(`/api/item/${item._id}/images`)
    .then(response => response.json())
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `Failed to fetch images for item ${item._id}: ${error.message}`
      )]);
    });
    
    setImages(fetchResult.images);

  },[item]);

  useEffect(() => {
    fetchImages(item);
  }, [item]);

  //----------------------------------------------------------------

  const setCurrentImage = useCallback( async (imageId) => {
    const index = await images.findIndex((element) => element._id === imageId);
    if (index >=0){
      images[index].index = index;
      setImage(images[index]);
    }
  }, [images]);

  const selectImage = useCallback((imageId) => {
    setImage(null);
    setCurrentImage(imageId);
  }, [setCurrentImage]);

  useEffect(() => {
    setCurrentImage(params.imageId);
  }, [images, params.imageId]);

  return(
    <>
      <PreviousNext
        objects={images}
        currentObject={image}
        onClick={selectImage}
        basePath={`/foray/${params.forayId}/item/${params.itemId}/image`}
      />

      {image ?
        <ImageDetails image={image}/>
        :
        <Row className="mb-4">
          <Col className="text-center">
            <BasicSpinner text={'Loading...'} />
          </Col>
        </Row>
      }

      <Row className="mb-2 align-items-center">
        <Col className="text-start">
          <Link to={`/foray/${params.forayId}/item/${params.itemId}`}>
            <Button className="text-nowrap">
              Back to Parent Item
            </Button>
          </Link>
        </Col>
        <Col className="text-end">
          <Button onClick={() => setModalShow(true)} disabled={!image}>
            Edit
          </Button>
        </Col>
      </Row>

      <ModalShell
        title="Update Image"
        show={modalShow}
        onHide={() => setModalShow(false)}
        hideFooter={true}
      >
        <ImageUpdateForm
          image={image}
          onCancel={() => setModalShow(false)}
          onUpdate={fetchImages}
        />
      </ModalShell>

      <Toaster toasts={toasts} />
    </>
  );
}
