import { Spinner } from 'react-bootstrap';

export default function BasicSpinner({className, text}){
  return(
    <div className={className}>
      <Spinner
        as="span"
        animation="border"
        role="status"
        aria-hidden="true"
        size="sm"
      />
      {' '}
      {text === undefined || text === null ? '' : text}
    </div>
  )
}
