import { Card, Col } from 'react-bootstrap';

//================================================================

export default function ForaysInfo() {
  return (
    <Card className="border-0">
      <Card.Body className="m-0 p-0">
        <Card.Title>
          Forays
        </Card.Title>
        <div className="mb-2">
          Each Foray is a collection of Items documented in the environment. Some details I am logging in these entries include:
        </div>
        <Col>
          <ul>
            <li>Before and After Images</li>
            <li>Geocoordinates</li>
            <li>Whether it was collected or left in place</li>
            <li>Plastic Number Marking(s)</li>
            <li>Descriptions of the item itself and surroundings</li>
            <li>Tags</li>
            <li>Timestamp</li>
          </ul>
        </Col>
      </Card.Body>
    </Card>
  );
}
