import { ToastContainer } from 'react-bootstrap';
import { useState } from 'react';
import { Toast } from 'react-bootstrap';

export function createToast(title, message){

  const array = new Uint32Array(1);
  window.crypto.getRandomValues(array);
  const key = array[0].toString(36);

  return(
    <ToastShell
      key={key}
      id={`toast-${key}`}
      title={title}
      message={message}
    />
  );
}

export function Toaster({toasts}){
  return(
    <ToastContainer position="bottom-end" className="p-3" style={{ zIndex: 9999 }}>
      {toasts}
    </ToastContainer>
  )
}

export function ToastShell({title, message, id, timestamp, onExited}) {
  
  const [show, setShow] = useState(true);

  return (
    <Toast
      onClose={() => setShow(false)}
      show={show}
      delay={process.env.REACT_APP_TOAST_TIMEOUT}
      autohide
      onExited={onExited ? () => {onExited(id)} : () => {}}
    >
      <Toast.Header>
        <strong className="me-auto">
          {title ? title : "Plasticene"}
        </strong>
        <small>
          {timestamp ? timestamp : `${new Date(Date.now()).toLocaleTimeString()}`}
        </small>
      </Toast.Header>
      <Toast.Body>
        {message ? message : "Set toast message with the \"message\" prop."}
      </Toast.Body>
    </Toast>
  );
}
