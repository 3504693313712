import { Link } from 'react-router-dom';
import { Col, Row, Button, Spinner, ToggleButton } from 'react-bootstrap';
import { useState, useCallback } from 'react';

//================================================================

export default function RowShell({linkTo, linkText, id, onDelete}){

  const [working, setWorking] = useState(false);
  const [deleteEnabled, setDeleteEnabled] = useState(false);

  //----------------------------------------------------------------

  const handleDeleteButtonClick = useCallback(async () => {
    setWorking(true);
    await onDelete(id);
    setWorking(false);
  }, [onDelete, id]);

  //----------------------------------------------------------------

  return(
    <Row className="mb-2 align-items-center">
      <Col className="text-start text-truncate">
        <Link
          key={`image-link-${id}`}
          to={linkTo}
        >
          {linkText}
        </Link>
      </Col>
      <Col className="text-end">
        <ToggleButton
          id={`deleteToggle-${id}`}
          type="checkbox"
          variant="outline-danger"
          size="sm"
          checked={deleteEnabled}
          value="1"
          onChange={(e) => setDeleteEnabled(e.currentTarget.checked)}
        >
          {deleteEnabled ? "Cancel" : <i className="bi bi-trash" /> }
        </ToggleButton>
        {' '}
        <Button
          className="btn-sm"
          variant="danger"
          onClick={handleDeleteButtonClick}
          hidden={!deleteEnabled}
          disabled={!deleteEnabled || working}
        >
          <span className="visually-hidden">Delete</span>
          {working ?
            <Spinner
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
              size="sm"
            />
            :
            <i className="bi bi-trash" />
          }
        </Button>
      </Col>
    </Row>
  );
}
