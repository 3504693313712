import { Col, Row} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { PlasticCodes } from '../../enums';
import { useCallback } from 'react';

//================================================================

export default function ItemDetails(props){

  const createOn = new Date(props.item.created * 1000).toLocaleString() || "unavailable";
  const modifiedOn = new Date(props.item.modified * 1000).toLocaleString() || "unavailable";
  const name = props.item.name || "unnamed";
  const itemDescription = props.item.item_description || "None.";
  const envDescription = props.item.env_description || "None.";
  const tags = props.item.tags.join(", ") || "Item has no tags";
  const plasticCodes = props.item.plastic_codes;
  const itemState  = props.item.item_state || "unset";
  const geocoordinates = props.item.geocoordinates || null;

  //----------------------------------------------------------------

  const displayPlasticCode = useCallback(() => {

    const keys = plasticCodes.map((plasticCode) =>{
      const key = Object.keys(PlasticCodes).find(key => PlasticCodes[key]['value'] === plasticCode);
      if (key){
        return PlasticCodes[key]['name'];
      } else {
        return `BAD VALUE ${props.item.plastic_code}`
      }
    });

    return keys;
  });

  //----------------------------------------------------------------

  return(
    <div className="mb-4">
      <Row>
        <Col className="text-truncate">
          Name: {name}
        </Col>
      </Row>
      {/*<Row>
        <Col className="text-truncate">
          ID: {props.item._id}
        </Col>
      </Row>*/}
      <Row>
        <Col>
          Item Description: {itemDescription}
        </Col>
      </Row>
      <Row>
        <Col>
          Environment Description: {envDescription}
        </Col>
      </Row>
      <Row>
        <Col>
          Geocoordinates:{' '}
          {geocoordinates ? <>
            <Link
              to={`https://www.openstreetmap.org/?mlat=${geocoordinates.lat}&mlon=${geocoordinates.lon}&zoom=${process.env.REACT_APP_OSM_ZOOM}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              View on OpenStreetMap
            </Link>
            {Object.entries(geocoordinates).map(([key,value]) =>
              <li key={`geocoordinates-${key}-${value}`}>
                {key}: {value}
              </li>
            )}
          </> : "Not captured."}
        </Col>
      </Row>
      <Row>
        <Col>
          Plastic Codes: {displayPlasticCode().join(", ") || "None assigned."}
        </Col>
      </Row>
      <Row>
        <Col>
          Status: {itemState}
        </Col>
      </Row>
      <Row>
        <Col>
          Tags: {tags}
        </Col>
      </Row>
      <Row>
        <Col>
          Added On: {createOn}
        </Col>
      </Row>
      {/*<Row>
        <Col>
          Last Updated: {modifiedOn}
        </Col>
      </Row>*/}
    </div>
  );
}
