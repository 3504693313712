import { Col, Row, Form, Button } from 'react-bootstrap';
import { useState, useCallback} from 'react';
import { formatTagsJSON } from '../../utils/utils';

import BasicSpinner from '../common/basic-spinner';
import { Toaster, createToast } from '../common/toasts';

//================================================================

export default function ForayCreateForm({onCreate, onCancel}) {

  //----------------------------------------------------------------
  // Hooks
  const titlePlaceholder = `New Foray: ${new Date().toLocaleString()}`;
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [tags, setTagsString] = useState("");
  const [working, setWorking] = useState(false);
  const [toasts, setToasts] = useState([]);

  //----------------------------------------------------------------
  // Functions

  const createForayHandler = useCallback(async () => {
    setWorking(true);

    const data = {
      'title': title ? title : titlePlaceholder,
      'description': description,
      'tags': formatTagsJSON(tags),
    }

    await fetch('/api/foray/', {
      method : 'POST',
      body : JSON.stringify(data),
      headers: {"Content-Type": "application/json"}
    })
    .then(async (response) => {
      const result = await response.json();
      if (response.ok) {
        onCreate(result);
      } else {
      setToasts([...toasts, createToast(
          `Failed to create new foray`,
          `${response.status} - ${JSON.stringify(result, null, "\t")}`
        )]);
      }
    })
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `Failed to create new foray: ${error.message}`
      )]);
    })

    setWorking(false);
  }, [title, titlePlaceholder, description, tags, onCreate]);

  //----------------------------------------------------------------

  return (
    <Form className="mb-4">

      <Form.Group className="mb-4">
        <Form.Label>Title</Form.Label>
        <Form.Control
          type="text"
          value={title}
          placeholder={titlePlaceholder}
          onChange={(event) => setTitle(event.target.value)}
          disabled={working}
        />
        <Form.Text className="text-muted">
          Give the Foray a Name.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          placeholder="Enter descrition"
          onChange={(event) => setDescription(event.target.value)}
          disabled={working}
        />
        <Form.Text className="text-muted">
            A short description of where you are and where you'll be going.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Label>Tags</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter tags"
          onChange={(event) => setTagsString(event.target.value)}
          disabled={working}
        />
        <Form.Text className="text-muted">
          Single-word tags separated by spaces, please.
        </Form.Text>
      </Form.Group>

      <Row className="align-items-center">
        <Col className="text-start">
          <Button
            variant="secondary"
            onClick={onCancel}
            disabled={working}
          >
            Cancel
          </Button>
        </Col>
        <Col className="text-end">
          <Button
            className="text-nowrap"
            variant="primary"
            onClick={createForayHandler}
            disabled={working}
          >
            {working ?
              <BasicSpinner text="Creating New Foray..." />
              :
              "Create New Foray"
            }
          </Button>
        </Col>
      </Row>

      <Toaster toasts={toasts} />

    </Form>
  );
}
