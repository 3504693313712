import { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";

//================================================================

const AuthContext = createContext();

//----------------------------------------------------------------

export default function AuthProvider({ children }) {

  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("plasticene") || "");
  const navigate = useNavigate();

  //----------------------------------------------------------------

  const loginAction = async (data) => {
    const authResult = await fetch('/api/auth/validate-credentials', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(data)
    })
    .then((response) => {
      if (response.ok) return response.json();
    })
    .catch((error) => console.error(error));
    
    if (authResult) {
      setUser(authResult);
      setToken(authResult.session_token);
      localStorage.setItem("plasticene", authResult.session_token);
        navigate("/forays/");
        return;
      }
    };

  const logOut = () => {
    setUser(null);
    setToken("");
    localStorage.removeItem("plasticene");
    navigate("/login");
  };

  //----------------------------------------------------------------

  return (
    <AuthContext.Provider value={{ token, user, loginAction, logOut }}>
      {children}
    </AuthContext.Provider>
  );

};

//----------------------------------------------------------------

export const useAuth = () => {
  return useContext(AuthContext);
};
