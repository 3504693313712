// Auth for Fast API

//https://fastapi.tiangolo.com/advanced/security/http-basic-auth/
//https://fastapi.tiangolo.com/tutorial/security/first-steps/

//Sessions and Cookies
//https://medium.com/@pradoshkumar.jena/session-based-authentication-with-fastapi-a-step-by-step-guide-ca19e98ce0f9

// Authentication for React

// https://www.robinwieruch.de/react-router-private-routes/
// https://dev.to/sharmaprash/a-guide-to-creating-protected-routes-with-react-router-dom-45f4
// https://www.digitalocean.com/community/tutorials/how-to-add-login-authentication-to-react-applications
// https://ui.dev/react-router-protected-routes-authentication
// https://blog.logrocket.com/authentication-react-router-v6/
// https://stackoverflow.com/questions/62378796/cannot-destructure-property-of-object-from-context

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import AuthProvider from "./utils/auth-provider";
import PrivateRoute from "./routes/private-route";

import Home from "./routes/home";
import Layout from "./routes/layout";
import Login from "./routes/login";
import NoPage from "./routes/no-page";

import ForaysPage from './components/foray/forays-page';
import ForayPage from './components/foray/foray-page';
import ItemPage from './components/item/item-page';
import ImagePage from './components/image/image-page';

export default function App() {
  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="*" element={<NoPage />} />
              <Route path="login" element={<Login />} />
              <Route element={<PrivateRoute />}>
                <Route path="forays" element={<ForaysPage />} />
              </Route>
              <Route element={<PrivateRoute />}>
                <Route path="foray/:forayId" element={<ForayPage />} />
              </Route>
              <Route element={<PrivateRoute />}>
                <Route path="foray/:forayId/item/:itemId" element={<ItemPage />} />
              </Route>
              <Route element={<PrivateRoute />}>
                <Route path="foray/:forayId/item/:itemId/image/:imageId" element={<ImagePage />} />
              </Route>
            </Route>
          </Routes>
        </AuthProvider>
      </Router>
    </div>
  );
}
