//----------------------------------------------------------------

const PlasticCodes = Object.freeze({
  UNKNOWN: {'name': 'UNKNOWN', 'value': -1},
  UNMARKED: {'name': 'UNMARKED', 'value': 0},
  PETE: {'name': '1 - PETE', 'value': 1},
  HDPE: {'name': '2 - HDPE', 'value': 2},
  PVC: {'name': '3 - PVC', 'value': 3},
  LDPE: {'name': '4 - LDPE', 'value': 4},
  PP: {'name': '5 - PP', 'value': 5},
  PS: {'name': '6 - PS', 'value': 6},
  OTHER: {'name': '7 - OTHER', 'value': 7}
});
exports.PlasticCodes = PlasticCodes;

//----------------------------------------------------------------

const ItemStates = Object.freeze({
  COLLECTED: "collected",
  DISPOSED: "disposed",
  PROCESSED: "processed",
  UNALTERED: "unaltered",
  UNSET: "unset"
});
exports.ItemStates = ItemStates;

//----------------------------------------------------------------

const ItemTypes = Object.freeze({
  MAIN: "main",
  DETAIL: "detail",
  FRAGMENT: "fragment"
});
exports.ItemTypes = ItemTypes;

//----------------------------------------------------------------

const ImageStates = Object.freeze({
  BEFORE: "before",
  AFTER: "after"
});
exports.ImageStates = ImageStates;

//----------------------------------------------------------------
