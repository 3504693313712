import { useState } from 'react';
import { FloatingLabel, Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import { useAuth } from "../utils/auth-provider";

//================================================================

export default function Login() {

  const auth = useAuth();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [working, setWorking] = useState(false);

  //----------------------------------------------------------------

  const handleSubmit = async (e) => {
    e.preventDefault();
    setWorking(true);
    await auth.loginAction({
      username: username,
      password: password,
    });
    setWorking(false);
  };

  //----------------------------------------------------------------

  return(
    <Row>
      <Col className="h2">
        Please Log In
      </Col>
      <Form onSubmit={handleSubmit}>
        <FloatingLabel
          controlId="username"
          label="Username"
          className="mb-2"
        >
          <Form.Control
            type="text"
            onChange={e => setUsername(e.target.value)}
            disabled={working}
          />
        </FloatingLabel>
        <FloatingLabel
          controlId="password"
          label="Password"
          className="mb-2"
        >
          <Form.Control
            type="password"
            onChange={e => setPassword(e.target.value)}
            disabled={working}
          />
        </FloatingLabel>
        <Col>
          <Button
            type="submit"
            disabled={working}
          >
            {working ? <>
              <Spinner
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
              size="sm"
            />{' '}
            Working...
            </> : "Submit"}
          </Button>
        </Col>
      </Form>
    </Row>
  )
}
