function formatTagsJSON(tagsString){
  const tagList = tagsString
    .toLowerCase() // convert to lowercase
    .split(",") // split into array
    .map(e=>e.replace(/[^a-zA-Z0-9 ]/g, "").trim()) // trim whitespace
    .filter(e=>e); // remove empty elements
  return [...new Set(tagList)]; // removes duplicates
}
exports.formatTagsJSON=formatTagsJSON;

function formatTagsMultipart(tagsString){
  const tagList = tagsString
    .toLowerCase() // convert to lowercase
    .split(",") // split into array
    .map(e=>e.replace(/[^a-zA-Z0-9 ]/g, "").trim()) // trim whitespace
    .filter(e=>e); // remove empty elements
  return [...new Set(tagList)].join(','); // removes duplicates
}
exports.formatTagsMultipart=formatTagsMultipart;


async function sendUpdateRequest(data, url) {
  const result = await fetch(url, {
    method : 'PUT',
    body : JSON.stringify(data),
    headers: {"Content-Type": "application/json"}
  })
  .then((response) => {
    if (response.ok) return response.json();
    throw new Error(`Failed to update foray: ${response.status} - ${response.json()}`);
  });
  return result;
}
exports.sendUpdateRequest=sendUpdateRequest;
