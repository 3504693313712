import { Col, Card, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";

//================================================================
 
export default function NoPage() {
  return (
    <Card className="border-0">
      <Card.Body className="text-center">
        <Card.Title>
          <div className="mb-4">
            404 - NOT FOUND
          </div>
        </Card.Title>
        <Col>
          <div className="mb-4">
            Unable to find the resource you requested.
          </div>
          <Link to="/">
            <Button>
             Home
            </Button>
          </Link>
        </Col>
      </Card.Body>
    </Card>
  );
}
