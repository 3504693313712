import { Outlet, Link } from 'react-router-dom';
import { Container, Breadcrumb, Row } from 'react-bootstrap';
import { useAuth } from "../utils/auth-provider";


//================================================================

const Layout = () => {

  const user = useAuth();

  return (
    <Container>

      <Row>
        <div className="display-6 mb-1 mt-4">
          The Plasticene
        </div>
        <div className="mb-3">
          A project by <Link to="https://phillipstearns.com" target="_blank">Phillip David Stearns</Link>
        </div>
      </Row>

      <hr className="mb-3" />
      
      <Outlet />

      <hr className="mb-3" />

      <Row>
        <Breadcrumb as="nav" className="d-flex justify-content-center">
          <Link to="/">
            Home
          </Link>&nbsp;|&nbsp;
          {user.token ?
            <>
              <Link to="/forays">
                Forays
              </Link>&nbsp;|&nbsp;
              <Link onClick={() => user.logOut()}>
                Sign out
              </Link>
            </>
            :
            <Link to="/login">
              Sign in
            </Link>
          }
        </Breadcrumb>
      </Row>

    </Container>
  )
};

export default Layout;
