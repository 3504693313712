import { Col, Row } from 'react-bootstrap';

//================================================================

export default function ForayDetails({foray}){

  const createOn = foray.created ? new Date(foray.created * 1000).toLocaleString() : "unavailable";
  const modifiedOn = foray.modified ? new Date(foray.modified * 1000).toLocaleString() : "unavailable";

  //----------------------------------------------------------------

  return(
    <div className="mb-4">
      <Row>
        <Col className="text-truncate">
          Title: {foray.title}
        </Col>
      </Row>
      {/*<Row>
        <Col className="text-truncate">
          ID: {foray._id}
        </Col>
      </Row>*/}
      <Row>
        <Col>
        Description: {foray.description}
        </Col>
      </Row>
      <Row>
        <Col>
        Tags: {foray.tags.join(", ")}
        </Col>
      </Row>
      <Row>
        <Col>
        Started On: {createOn}
        </Col>
      </Row>
     {/* <Row>
        <Col>
        Last Updated: {modifiedOn}
        </Col>
      </Row>*/}
    </div>
  );
}
