import { useParams, Link, useNavigate } from "react-router-dom";
import { Col, Row, Button } from 'react-bootstrap';
import { useState, useEffect, useCallback } from 'react';

import ModalShell from '../common/modal-shell';
import RowShell from '../common/row-shell';
import BasicSpinner from '../common/basic-spinner';
import { Toaster, createToast } from '../common/toasts';
import PreviousNext from '../common/previous-next';

import ForayDetails from './foray-details';
import ForayUpdateForm from './foray-update-form';
import ItemCreateForm from '../item/item-create-form';

//================================================================

export default function ForayPage(){

  //----------------------------------------------------------------
  //Hooks and Variables

  const params = useParams();
  const redirect = useNavigate();

  const [forays, setForays] = useState([]);
  const [foray, setForay] = useState();
  const [createModalShow, setCreateModalShow] = useState(false);
  const [updateModalShow, setUpdateModalShow] = useState(false);
  const [items, setItems] = useState(null);
  const [toasts, setToasts] = useState([]);


  //----------------------------------------------------------------
  // Fetching and setting Forays

  const fetchForays = useCallback(async () => {
    await setForays(await fetch("/api/foray/")
      .then((response) => {
        if (response.ok) return response.json();
        return [];
      })
      .catch((error) => {
        setToasts([...toasts, createToast(
          "Error",
          `Failed to fetch forays: ${error.message}`
        )]);
      })
    );
  });

  useEffect(() => {
    fetchForays();
  }, []);

  //----------------------------------------------------------------
  // Select the current Foray by ID

  const setCurrentForay = useCallback(async (forayId) => {
    const index = await forays.findIndex(foray => foray._id === forayId);
    if (index >= 0){
      forays[index].index = index;
      setForay(forays[index]);
    }
  }, [forays]);

  useEffect(() => {
    setCurrentForay(params.forayId);
  }, [forays]);

  //----------------------------------------------------------------

  const addItemToForay = useCallback(async (item) => {
    if (!item) return null;

    await fetch(`/api/foray/${params.forayId}`, {
      method: 'PUT',
      body: JSON.stringify({'add_items':[item._id]}),
      headers: {"Content-Type": "application/json"}
    })
    .then(async (response) => {
      const result = await response.json();
      if(response.ok){
        setForay(result);
        setCreateModalShow(false);
      } else {
        setToasts([...toasts, createToast(
          "Failed to add item to foray",
          `${response.status} - ${JSON.stringify(result, null, "\t")}`
        )]);
      }
    })
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `While adding item ${item._id} to foray ${params.forayId}: ${error.message}`
      )]);
    });
  });

  //----------------------------------------------------------------

  const removeItemFromForay = useCallback(async (itemId) => {
    await fetch(`/api/foray/${params.forayId}`, {
      method: 'PUT',
      body: JSON.stringify({'remove_items' : [itemId]}),
      headers: {"Content-Type": "application/json"}
    })
    .then((response) => {
      if(response.ok){
        setItems(
          items.filter(item => item._id !== itemId)
        );
      } else {
        setToasts([...toasts, createToast(
          "Failed to remove item from foray",
          `${response.status} - ${response.json()}`
        )]);
      }
    })
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `While removing item ${itemId} from foray ${params.forayId}: ${error.message}`
      )]);
    });
  }, [items]);

  //----------------------------------------------------------------

  const fetchItems = useCallback(async (foray) => {
    if (!foray) return;

    const fetchResult = await fetch(`/api/foray/${foray._id}/items`)
    .then((response) => {
      if (response.ok){
        return response.json();
      }
    }).catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `While fetching items for foray ${foray._id}: ${error.message}`
      )]);
    });
    
    setItems(fetchResult.items);
    
  }, [foray]);

  useEffect(() => {
    setItems(null);
    fetchItems(foray);
  },[foray]);

  //----------------------------------------------------------------

  return(
    <>
      <PreviousNext
        objects={forays}
        currentObject={foray}
        onClick={setCurrentForay}
        basePath={'/foray'}
      />

      <Row className="mb-2">
        <Col className="h4">Foray:</Col>
      </Row>

      {foray ?
        <ForayDetails foray={foray}/>
        :
        <Row className="mb-4">
          <Col className="text-center">
            <BasicSpinner text={'Loading...'} />
          </Col>
        </Row>
      }

      <Row className="mb-2 align-items-center">
        <Col className="text-start">
          <Link to={'/forays/'}>
            <Button className="text-nowrap">
              Back to Forays
            </Button>
          </Link>
        </Col>
        <Col className="text-end">
          <Button
            onClick={() => setUpdateModalShow(true)}
            disabled={!foray}
          >
            Edit
          </Button>
        </Col>
      </Row>

      <hr></hr>

      {items !== null ?
        <>
          <Row className="mb-3 align-items-center">
            <Col className="h4 my-0 text-start">Items:</Col>
            <Col className="text-end">
              <Button
                className="text-nowrap"
                onClick={() => setCreateModalShow(true)}
              >
                Add New Item
              </Button>
            </Col>
          </Row>

          {items.map(item => <RowShell
            key={`item-row-${item._id}`}
            id={item._id}
            linkTo={`./item/${item._id}`}
            linkText={item.name}
            onDelete={removeItemFromForay}
          />)}
        </>
        :
        <Row className="mb-3">
          <Col className="text-center">
            <BasicSpinner text={'Loading...'} />
          </Col>
        </Row>
      }

      <ModalShell
        key="createModal"
        title="Add New Item"
        show={createModalShow}
        onHide={() => setCreateModalShow(false)}
        hideFooter={true}
      >
        <ItemCreateForm
          onCancel={() => setCreateModalShow(false)}
          onCreate={addItemToForay}
        />
      </ModalShell>

      <ModalShell
        key="updateModal"
        title="Update Foray"
        show={updateModalShow}
        onHide={() => setUpdateModalShow(false)}
        hideFooter={true}
      >
        <ForayUpdateForm
          foray={foray}
          onCancel={() => setUpdateModalShow(false)}
          onUpdate={fetchForays}
        />
      </ModalShell>

      <Toaster toasts={toasts} />

    </>
  );
}
